import React, { useRef } from "react"

import { Layout } from "@components/layout"
import { Gallery2021 } from "@components/standard"

const Gallery2020Page = ({ pageContext, location }) => {
  const { page } = pageContext
  return (
    <Layout {...page}>
      <Gallery2021 />
    </Layout>
  )
}

export default Gallery2020Page
